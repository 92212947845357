<script lang="ts" setup>
const { injectSkinClasses } = useSkins()

// ℹ️ This will inject classes in body tag for accurate styling
injectSkinClasses()
</script>

<template>
  <div class="layout-wrapper layout-blank">
    <div class="k-boa7si py-6">
      <a class="k-3fv1x8">
        <div class="fullpage-logo px-8">
          <img key="logo-with-title" src="/logo_konsento_black.png" alt="Konsento Logo">
        </div>
      </a>
      <div class="navbar-custom ml-auto">
        <NavBarI18n />
      </div>
    </div>
    <slot />
  </div>
</template>

<style>
.layout-wrapper.layout-blank {
  flex-direction: column;
}

.fullpage-logo {
  block-size: 40px;
  inline-size: 210px;
}
</style>
